// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-style {
    width: 100%;
    padding: 10px 0px;
    border: none;
    font-size: 14px;
    box-sizing: border-box;
    outline: none;
    font-family: Arial, Roboto;
}

.icon-style {
    margin-right: 8px;
    color: #888;
}

.label-style {
    font-size: 14px;
    font-weight: bold;
    color: #3f4a63;
    margin-bottom: 8px;
    display: inline-block
}

.error-text{
    color: red;
    font-size: 12px;
}

.dialog-container {
    padding: 16px;
  }`, "",{"version":3,"sources":["webpack://./src/mainComponents/universities/assets/universitiesStyleSheet.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,YAAY;IACZ,eAAe;IACf,sBAAsB;IACtB,aAAa;IACb,0BAA0B;AAC9B;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB;AACJ;;AAEA;IACI,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,aAAa;EACf","sourcesContent":[".input-style {\n    width: 100%;\n    padding: 10px 0px;\n    border: none;\n    font-size: 14px;\n    box-sizing: border-box;\n    outline: none;\n    font-family: Arial, Roboto;\n}\n\n.icon-style {\n    margin-right: 8px;\n    color: #888;\n}\n\n.label-style {\n    font-size: 14px;\n    font-weight: bold;\n    color: #3f4a63;\n    margin-bottom: 8px;\n    display: inline-block\n}\n\n.error-text{\n    color: red;\n    font-size: 12px;\n}\n\n.dialog-container {\n    padding: 16px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
