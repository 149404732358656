import React, {useEffect} from "react";
import "./assets/style/errorPageStyleSheet.css";
import error from "./assets/images/404error.png";
import SoftButton from "../../components/SoftButton";
import { useNavigate} from "react-router-dom";
import {useSoftUIController} from "../../context";
import {setLayout} from "../../store/actions/app-actions";





const ErrorPages = () => {
    const [controller, dispatch] = useSoftUIController();
    const navigate = useNavigate();


    const handleRedirectToDashboard = () => {

        navigate("/dashboard");

    }

    useEffect(() => {
        setLayout(dispatch, "page");
    }, []);
    return (
        <div className="error-page-container">
            <div className="error-message">
                <h1 className="error-code">Error 404</h1>
                <p className="text">Page not found</p>
                <p className="suggestion">
                    We suggest you to go to the homepage while we solve this issue.
                </p>
                <SoftButton color="dark" className="homepage-button"
                            onClick={() => handleRedirectToDashboard()}
                >Go to home page</SoftButton>
            </div>
            <div className="error-image">
                <div className="character">
                    {/*<div className="speech-bubble">Ooops!</div>*/}
                    <img
                        src={error}
                        alt="3D Character"
                        className="character-image"
                    />
                </div>
            </div>
        </div>
    );

}
export default ErrorPages;