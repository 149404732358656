import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import { Grid } from '@mui/material';
import DashboardLayout from 'reusableComponents/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../Navbars/DashboardNavbar';
import Table from 'reusableComponents/Tables/Table/index';
import SuiButton from '../../../components/SoftButton';
import { IoIosAdd } from 'react-icons/io';
import Checkbox from '@mui/material/Checkbox';
import Filter from '../../Filters/Filter';
import InsightsCard from 'reusableComponents/Cards/StatisticsCards/InsightsCard';

const TableCard = ({
  rows,
  title,
  showAddButton,
  ButtonName,
  onButtonClick,
  loadingData,
  showIcon = true,
  text,
  showCheckBox,
  onCheckChange,
  optionChecked,
  collapsed,
  collapsedRows,
  count,
  size,
  page,
  handlePageChange,
  handleRowsPerPageChange,
  filters,
  filterData,
  handleFilterChange,
  displayFooter = false,
  footerRow,
  selector,
  cardsData,
  cardsLoading,
}) => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {cardsData && cardsData.length > 0 && (
        <Grid container spacing={3} style={{ marginBottom: '24px' }}>
          {cardsData.map((card, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              {cardsLoading ? (
                <p>Loading...</p>
              ) : (
                <InsightsCard
                  title={card.title}
                  count={card.count}
                  percentage={card.percentage}
                  icon={card.icon}
                  direction={card.direction}
                  comparisonText={card.comparisonText}
                />
              )}
            </Grid>
          ))}
        </Grid>
      )}

      {filters && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '1rem',
            gap: '1rem',
            paddingLeft: '1rem',
            marginTop: '1rem',
          }}
        >
          {filters.map((filter, index) => (
            <Filter
              key={index}
              type={filter.type}
              label={filter.label}
              value={filterData[filter.key]}
              filterData={filter.options || []}
              onChange={(e) =>
                handleFilterChange(filter.key, filter.type === 'date' ? e : e.target.value)
              }
            />
          ))}
        </div>
      )}
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">{title}</SoftTypography>
              <SoftBox display="flex" alignItems="center" gap={2}>
                {showCheckBox && (
                  <SoftBox display="flex" alignItems="center">
                    <Checkbox checked={optionChecked} onChange={onCheckChange} />
                    <SoftTypography sx={{ fontSize: '12px' }}>{text}</SoftTypography>
                  </SoftBox>
                )}
                {showAddButton && (
                  <SuiButton
                    variant="gradient"
                    color="primary"
                    size="medium"
                    onClick={onButtonClick}
                  >
                    {showIcon && <IoIosAdd />}
                    {ButtonName}
                  </SuiButton>
                )}
              </SoftBox>
            </SoftBox>
            <SoftBox
              sx={{
                '& .MuiTableRow-root:not(:last-child)': {
                  '& td': {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {rows.length > 0 ? (
                <Table
                  rows={rows}
                  collapsed={collapsed}
                  collapsedRows={collapsedRows}
                  selector={selector}
                  displayFooter={displayFooter}
                  footerRow={footerRow}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  handlePageChange={handlePageChange}
                  page={page}
                  pageSize={size}
                  count={count}
                />
              ) : loadingData ? (
                <p style={{ textAlign: 'center', padding: '50px' }}> Loading... </p>
              ) : (
                <p style={{ textAlign: 'center', padding: '50px' }}> No results found!</p>
              )}
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
};

TableCard.propTypes = {
  title: PropTypes.string,
  ButtonName: PropTypes.string,
  text: PropTypes.string,
  selector: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.object),
  collapsedRows: PropTypes.arrayOf(PropTypes.object),
  showAddButton: PropTypes.bool,
  showIcon: PropTypes.bool,
  showCheckBox: PropTypes.bool,
  loadingData: PropTypes.bool,
  optionChecked: PropTypes.bool,
  onButtonClick: PropTypes.func,
  onCheckChange: PropTypes.func,
  count: PropTypes.number,
  size: PropTypes.number,
  page: PropTypes.number,
  handlePageChange: PropTypes.func,
  handleRowsPerPageChange: PropTypes.func,
  displayFooter: PropTypes.bool,
  collapsed: PropTypes.bool,
  footerRow: PropTypes.object,
  filters: PropTypes.arrayOf(PropTypes.object),
  handleFilterChange: PropTypes.func,
  filterData: PropTypes.object,
  cardsData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.shape({
        text: PropTypes.string,
        fontWeight: PropTypes.oneOf(['light', 'regular', 'medium', 'bold']),
      }).isRequired,
      count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      percentage: PropTypes.shape({
        color: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        icon: PropTypes.node.isRequired,
      }),
      icon: PropTypes.shape({
        color: PropTypes.string,
        component: PropTypes.node.isRequired,
      }).isRequired,
      direction: PropTypes.oneOf(['left', 'right']),
      comparisonText: PropTypes.string,
    })
  ),
  cardsLoading: PropTypes.bool,
};

export default TableCard;
