import Tables from "../../reusableComponents/Tables/Table/TableCard";
import React, { useState, useEffect } from 'react';
import {
    activateUsers,
    getFreeAgentsList,

} from "../../axios-client";
import {formatDataToUrlQueryParams, GradientSwitchStyle} from "../../utlis/generalUtils";
import AvatarComponent from "../../reusableComponents/Avatar/AvatarComponent";
import {useSoftUIController} from "../../context";
import {SUPER_ADMIN_ROLE} from "../../utlis/constant";
import Badge from "../../reusableComponents/Badge/Badge";
import SuiButton from "../../components/SoftButton";
import CircularProgress from '@mui/material/CircularProgress';
import { format } from 'date-fns';
import { FaMinusCircle } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import userAvatar from "../../assets/images/userAvatar.png";
import { IconButton } from '@mui/material';
import Tooltip from "@mui/material/Tooltip";
import KeyIcon from '@mui/icons-material/Key';
import ResetPasswordDialog from "../librarians/ResetPasswordDialog";
import SnackbarNotification from "../../reusableComponents/Snackbar/SnackbarNotification";
const DEFAULT_AVATAR_URL =userAvatar;

const FreeAgents = (props) => {
    const [controller, dispatch] = useSoftUIController();
    const {role} = controller;
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [freeAgentsCount, setFreeAgentsCount] = useState(0);
    const [notification, setNotification] = useState({show: false, snackbarMessage: '', severity: ""})
    const [isActivating, setIsActivating] = useState(false);
    const [updatedUserIds, setUpdatedUserIds] = useState([]);
    const [filterData, setFilterData] = useState({
        page: 1,
        size: 10,
        email:'',
        name:'',

    });
    const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
    const [selectedFreeAgent, setSelectedFreeAgent] = useState(null);

    useEffect(() => {
            fetchFreeAgents(filterData);
    }, [filterData, role, isActivating]);


    const handleOpenResetPasswordDialog = (freeAgent) => {
        setSelectedFreeAgent(freeAgent);
        setOpenResetPasswordDialog(true);
    };

    const handleCloseResetPasswordDialog = () => {
        setOpenResetPasswordDialog(false);
        setSelectedFreeAgent(null);
    };

    const handlePageChange = (newPage) => {
        setFilterData((prev) => ({ ...prev, page: newPage }));
    };

    const handleRowsPerPageChange = (newSize) => {
        setFilterData((prev) => ({ ...prev, size: newSize, page: 1 }));
    };

    const handleUserStatus = (ids, isActive) => {
        if (ids.length === 0) {
            setNotification({
                show: true,
                snackbarMessage: 'No inactive free-agents to activate!',
                severity: 'info',
            });
            return;
        }
        setIsActivating(true)
        const userIds = Array.isArray(ids) ? ids.join(',') : ids;
        const payload = { is_active: isActive };
        setUpdatedUserIds(userIds)
        activateUsers(userIds, payload)
            .then(() => {
                setIsActivating(false)
                const snackbarMessage = payload.is_active ? 'Free agent activated successfully!' : 'Free agent deactivated successfully!';
                setNotification({
                    show: true,
                    snackbarMessage: snackbarMessage,
                    severity: "success"
                });
            })
            .catch(err => {
                setIsActivating(false)
                console.error('Error activating user:', err);
                setNotification({
                    show: true,
                    snackbarMessage: 'Failed to activate free-agent. Please try again!',
                    severity: "error"
                })
            });
    }

    const fetchFreeAgents = () => {
        setLoading(true);
        const queryParams = formatDataToUrlQueryParams(filterData);
        if (role === SUPER_ADMIN_ROLE) {
            getFreeAgentsList(`?${queryParams}`)
                .then(res => {
                    const formattedRows = res.data.free_agents.map(freeAgent => {
                        const badgeColor = freeAgent.is_active ? "success" : "secondary";
                        const badgeContent = freeAgent.is_active ? "Active" : "Inactive";
                        const variant = "gradient";
                        return {
                            "Name": (
                                <AvatarComponent
                                    name={freeAgent.name}
                                    image={freeAgent.profile_picture ?? DEFAULT_AVATAR_URL}
                                    bgColor={"secondary"}
                                    variant={"rounded"}
                                />
                            ),
                            "Email": freeAgent.email,
                            "Registred date": format(new Date(freeAgent.registration_date), 'MMM dd, yyyy hh:mm a'),
                            "Status": (
                                <Badge
                                    color={badgeColor}
                                    badgeContent={badgeContent}
                                    variant={variant}
                                />
                            ),
                            "Action": (
                                <>
                                    <Tooltip title="Reset password">
                                        <IconButton
                                            onClick={() => handleOpenResetPasswordDialog(freeAgent)}
                                            style={{ cursor: 'pointer' }}
                                            size="small"
                                        >
                                            <KeyIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            ),
                            "Activate": (
                                <>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <GradientSwitchStyle
                                            checked={freeAgent.is_active}
                                            onChange={() => handleUserStatus([freeAgent.id], !freeAgent.is_active)}
                                            icon={<FaMinusCircle />}
                                            checkedIcon={<FaCheckCircle />}
                                            disabled={isActivating && updatedUserIds.includes(freeAgent.id)}
                                        />
                                        {isActivating && updatedUserIds.includes(freeAgent.id) && (
                                            <CircularProgress size={20} style={{ marginLeft: 10 }} />
                                        )}
                                    </div>
                                </>
                            ),
                        };
                    });

                    setFreeAgentsCount(res.data.count);
                    setRows(formattedRows);
                    setLoading(false);
                })
                .catch(error => {
                });
        }
    };

    const handleFilterChange = (key, value) => {
        setFilterData({ ...filterData, [key]: value });
    };

    let filters = [
        {
            key: 'email',
            label: 'Search by agent email',
            type: 'input',
        },
        {
            key: 'name',
            label: 'Search by agent name',
            type: 'input',
        }
    ];


    return (
        <div>
            <Tables
                rows={rows}
                title={"Free Agents"}
                showAddButton={false}
                loadingData={loading}
                size={filterData.size}
                page={filterData.page}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                count={freeAgentsCount}
                filters={filters}
                filterData={filterData}
                handleFilterChange={handleFilterChange}
            />
            {openResetPasswordDialog && (
                <ResetPasswordDialog
                    open={openResetPasswordDialog}
                    onClose={handleCloseResetPasswordDialog}
                    fetchData={fetchFreeAgents}
                    selectedUserData={selectedFreeAgent}
                    isSuperAdmin={false}
                    setSnackbar={setNotification}
                />
            )}

            <SnackbarNotification
                notification={notification}
                setNotification={setNotification}
            />
        </div>
    );
};

export default FreeAgents;
