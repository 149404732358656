import React, { useState, useEffect } from 'react';
import { getOrdersList, getOrdersReport, captureOrder, refundPayment } from '../../axios-client';
import Tables from '../../reusableComponents/Tables/Table/TableCard';
import { formatDataToUrlQueryParams, getStatusIcon, getFormattedTodayDate } from '../../utlis/generalUtils';
import Tooltip from '@mui/material/Tooltip';
import logo from 'assets/images/logo.png';
import { downloadInvoicePDF } from './helper';
import { FaDownload } from 'react-icons/fa6';
import { MdDone, MdOutlineAttachMoney, MdOutlineRemove } from 'react-icons/md';
import { GrInProgress } from 'react-icons/gr';
import { HiOutlineClipboardDocumentCheck } from 'react-icons/hi2';
import { useSoftUIController } from '../../context';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import RefundDialog from './RefundDialog';
import SnackbarNotification from '../../reusableComponents/Snackbar/SnackbarNotification';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import { SUPER_ADMIN_ROLE } from '../../utlis/constant';

const PaymentList = () => {
  const [controller] = useSoftUIController();
  const { role, profileData } = controller;

  const rolesViewCards = ['super-admin', 'free-agent'];
  const rolesCanSeeRevenue = ['super-admin'];
  const isFreeAgent = role === 'free-agent';

  const [orderData, setOrderData] = useState({
    totalOrders: 0,
    ordersInProcess: 0,
    ordersCompleted: 0,
    totalRevenue: 0,
    percentageChanges: {},
  });
  const [orderDataLoading, setOrderDataLoading] = useState(true);

  // State for the table data
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ordersCount, setOrdersCount] = useState(0);

  // Get today's date for default filter values
  const today = getFormattedTodayDate();

  // State for pagination and filters
  const [filterData, setFilterData] = useState({
    page: 1,
    size: 10,
    from: today,
    to: today,
    status: '',
    order_id: '',
    email: '',
  });

  const [statuses] = useState([
    { id: '', name: 'Select Status' },
    { id: 'completed', name: 'Completed' },
    { id: 'pending', name: 'Pending' },
    { id: 'failed', name: 'Failed' },
    { id: 'refunded', name: 'Refunded' }
    
  ]);

  // State for refund dialog and notifications
  const [openDialog, setOpenDialog] = useState(false);
  const [refundType, setRefundType] = useState('custom');
  const [refundAmount, setRefundAmount] = useState(0);
  const [description, setDescription] = useState('');
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [notification, setNotification] = useState({
    show: false,
    snackbarMessage: '',
    severity: '',
  });

  // Handle page change
  const handlePageChange = (newPage) => {
    setFilterData((prev) => ({ ...prev, page: newPage }));
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (newSize) => {
    setFilterData((prev) => ({ ...prev, size: newSize, page: 1 }));
  };

  // Handle filter change
  const handleFilterChange = (key, value) => {
    if (key === 'from' || key === 'to') {
      setFilterData((prev) => ({
        ...prev,
        from: value.from,
        to: value.to,
        page: 1,
      }));
    } else {
      setFilterData((prev) => ({
        ...prev,
        [key]: value,
        page: 1,
      }));
    }
  };

  // Handle refund click
  const handleRefundClick = (order) => {
    setRefundAmount(parseFloat(order.amount));
    setOpenDialog(true);
    setSelectedOrder(order);
  };

  // Handle close dialog
  const handleCloseDialog = () => {
    setSelectedOrder(null);
    setLoading(false);
    setRefundAmount(0);
    setOpenDialog(false);
  };

  // Handle refund submit
  const handleRefundSubmit = () => {
    setLoading(true);
    refundPayment(selectedOrder.id, refundAmount, description)
      .then((res) => {
        setNotification({
          show: true,
          snackbarMessage: 'Amount refunded successfully!',
          severity: 'success',
        });
        setOpenDialog(false);
        setLoading(false);
        refreshList();
      })
      .catch((e) => {
        setNotification({
          show: true,
          snackbarMessage: 'An error occurred. Please try again.',
          severity: 'error',
        });
        setOpenDialog(false);
        setLoading(false);
      });
  };

  // Function to fetch and refresh the orders list
  const refreshList = () => {
    const queryParams = formatDataToUrlQueryParams(filterData);
    getOrdersList(`?${queryParams}`)
      .then((res) => {
        const formattedRows = res.data.orders.map((order) => {
          const row = {
            'Order ID': order.order_id,
            Date: order.date,
            ...(order.payer_email ? { Email: order.payer_email.toLowerCase() } : {}),
            'Payment Method': <>{getStatusIcon(order?.payment_method)}</>,
            'Document title': order.articles.map((article) => article.title).join(', '),
            Pages: order.articles.map((article) => article.page_count).join(', '),
            Status: <>{getStatusIcon(order?.status)}</>,
            'Amount (USD)': `$${order.amount}`,
          };

          const actions = [];
          if (order.download_invoice) {
            actions.push(
              <Tooltip title="Download invoice" key="download">
                <span>
                  <FaDownload
                    onClick={() => downloadInvoicePDF(order, profileData, logo)}
                  />
                </span>
              </Tooltip>
            );
          }

          if (role === SUPER_ADMIN_ROLE && order?.status === 'COMPLETED') {
            actions.push(
              <Button
                title="Refund"
                key="refund"
                onClick={() => handleRefundClick(order)}
              >
                {getStatusIcon('REFUNDED-ICON')}
              </Button>
            );
          }

          row['Action'] = <>{actions}</>;
          return row;
        });

        setRows(formattedRows);
        setLoading(false);
        setOrdersCount(res.data.count);
      })
      .catch((error) => {
        console.error('Error fetching orders:', error);
        setLoading(false);
      });
  };

  // Fetch the orders list
  useEffect(() => {
    refreshList();
  }, [filterData, profileData]);

  // Fetch the order insights data
  useEffect(() => {
    if (rolesViewCards.includes(role)) {
      getOrdersReport()
        .then((response) => {
          setOrderData({
            totalOrders: response.data.total_orders,
            ordersInProcess: response.data.orders_in_process,
            ordersCompleted: response.data.orders_completed,
            totalRevenue: response.data.total_revenue || 0,
            percentageChanges: response.data.percentage_changes || {},
          });
          setOrderDataLoading(false);
        })
        .catch((error) => {
          console.error('Failed to fetch order data:', error);
          setOrderDataLoading(false);
        });
    } else {
      setOrderDataLoading(false);
    }
  }, [role]);

  // Define filters
  let filters = [
    {
      key: 'from',
      label: 'Start Date',
      type: 'date',
    },
    {
      key: 'status',
      label: 'Payment Status',
      type: 'select',
      options: statuses.map((status) => ({ id: status.id, label: status.name })),
    },
  ];

  if (role === SUPER_ADMIN_ROLE) {
    filters = [
      ...filters,
      {
        key: 'order_id',
        label: 'Order ID',
        type: 'input',
      },
      {
        key: 'email',
        label: 'Email',
        type: 'input',
      },
    ];
  }

  // Define the data for the cards
  const allCardsData = [
    {
      title: { text: 'Total Orders', fontWeight: 'medium' },
      count: orderData.totalOrders,
      percentage: isFreeAgent
        ? null
        : {
            color:
              orderData.percentageChanges.total_orders > 0
                ? 'success'
                : orderData.percentageChanges.total_orders < 0
                ? 'error'
                : 'warning',
            text:
              orderData.percentageChanges.total_orders != null
                ? `${Math.abs(orderData.percentageChanges.total_orders).toFixed(2)}%`
                : '0.0%',
            icon:
              orderData.percentageChanges.total_orders > 0 ? (
                <TrendingUpIcon fontSize="small" sx={{ color: 'green' }} />
              ) : orderData.percentageChanges.total_orders < 0 ? (
                <TrendingDownIcon fontSize="small" sx={{ color: 'red' }} />
              ) : (
                <MdOutlineRemove fontSize="small" color="gray" />
              ),
          },
      icon: { color: 'primary', component: <HiOutlineClipboardDocumentCheck /> },
      direction: 'left',
      comparisonText: isFreeAgent ? null : 'Compared to yesterday',
    },
    {
      title: { text: 'Orders in Process', fontWeight: 'medium' },
      count: orderData.ordersInProcess,
      percentage: isFreeAgent
        ? null
        : {
            color:
              orderData.percentageChanges.orders_in_process > 0
                ? 'success'
                : orderData.percentageChanges.orders_in_process < 0
                ? 'error'
                : 'warning',
            text:
              orderData.percentageChanges.orders_in_process != null
                ? `${Math.abs(orderData.percentageChanges.orders_in_process).toFixed(2)}%`
                : '0.0%',
            icon:
              orderData.percentageChanges.orders_in_process > 0 ? (
                <TrendingUpIcon fontSize="small" sx={{ color: 'green' }} />
              ) : orderData.percentageChanges.orders_in_process < 0 ? (
                <TrendingDownIcon fontSize="small" sx={{ color: 'red' }} />
              ) : (
                <MdOutlineRemove fontSize="small" color="gray" />
              ),
          },
      icon: { color: 'info', component: <GrInProgress /> },
      direction: 'left',
      comparisonText: isFreeAgent ? null : 'Compared to yesterday',
    },
    {
      title: { text: 'Orders Completed', fontWeight: 'medium' },
      count: orderData.ordersCompleted,
      percentage: isFreeAgent
        ? null
        : {
            color:
              orderData.percentageChanges.orders_completed > 0
                ? 'success'
                : orderData.percentageChanges.orders_completed < 0
                ? 'error'
                : 'warning',
            text:
              orderData.percentageChanges.orders_completed != null
                ? `${Math.abs(orderData.percentageChanges.orders_completed).toFixed(2)}%`
                : '0.0%',
            icon:
              orderData.percentageChanges.orders_completed > 0 ? (
                <TrendingUpIcon fontSize="small" sx={{ color: 'green' }} />
              ) : orderData.percentageChanges.orders_completed < 0 ? (
                <TrendingDownIcon fontSize="small" sx={{ color: 'red' }} />
              ) : (
                <MdOutlineRemove fontSize="small" color="gray" />
              ),
          },
      icon: { color: 'warning', component: <MdDone /> },
      direction: 'left',
      comparisonText: isFreeAgent ? null : 'Compared to yesterday',
    },
    {
      title: { text: 'Total Revenue', fontWeight: 'medium' },
      count: `$${orderData.totalRevenue.toFixed(2)}`,
      percentage: isFreeAgent
        ? null
        : {
            color:
              orderData.percentageChanges.total_revenue > 0
                ? 'success'
                : orderData.percentageChanges.total_revenue < 0
                ? 'error'
                : 'warning',
            text:
              orderData.percentageChanges.total_revenue != null
                ? `${Math.abs(orderData.percentageChanges.total_revenue).toFixed(2)}%`
                : '0.0%',
            icon:
              orderData.percentageChanges.total_revenue > 0 ? (
                <TrendingUpIcon fontSize="small" sx={{ color: 'green' }} />
              ) : orderData.percentageChanges.total_revenue < 0 ? (
                <TrendingDownIcon fontSize="small" sx={{ color: 'red' }} />
              ) : (
                <MdOutlineRemove fontSize="small" color="gray" />
              ),
          },
      icon: { color: 'error', component: <MdOutlineAttachMoney /> },
      direction: 'left',
      comparisonText: isFreeAgent ? null : 'Compared to yesterday',
    },
  ];

  // Filter the cards based on the role
  const cardsData = allCardsData.filter((card) => {
    if (card.title.text === 'Total Revenue') {
      return rolesCanSeeRevenue.includes(role);
    }
    return rolesViewCards.includes(role);
  });

  return (
    <>
      <Tables
        rows={rows}
        title="Order List"
        loadingData={loading}
        size={filterData.size}
        page={filterData.page}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        count={ordersCount}
        filters={filters}
        filterData={filterData}
        handleFilterChange={handleFilterChange}
        cardsData={cardsData}
        cardsLoading={orderDataLoading}
      />

      <RefundDialog
        open={openDialog}
        onClose={handleCloseDialog}
        refundType={refundType}
        setRefundType={setRefundType}
        refundAmount={refundAmount}
        setRefundAmount={setRefundAmount}
        selectedOrder={selectedOrder}
        description={description}
        setDescription={setDescription}
        onSubmit={handleRefundSubmit}
        loading={loading}
      />

      <SnackbarNotification
        notification={notification}
        setNotification={setNotification}
      />
    </>
  );
};

export default PaymentList;
