import {Document, Packer, Paragraph, TextRun} from "docx";
import paypalicon from "../mainComponents/payment/assets/images/paypal-icon.png";
import visa from "../mainComponents/payment/assets/images/visa.png";
import master from "../mainComponents/payment/assets/images/mastercard.png";
import '../mainComponents/payment/assets/styles/paymentListStyleSheet.css';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import RotateLeftRoundedIcon from '@mui/icons-material/RotateLeftRounded';
import {MIME_TYPE_TO_EXTENSION, RTL_LANGUAGES} from "./constant";
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
/**
 * This function takes a date string as an argument, creates a new Date object from it,
 * and then uses the toLocaleDateString() method to format the date according to the
 * specified options
 * @param dateString
 * @returns {string}
 */
export function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}

/**
 * This function returns aligned text from right to the left for arabic and vis versa for other languages
 * specified options
 * @param language
 */
export const getScriptDirection = (language) => {

    return RTL_LANGUAGES.includes(language) ? 'rtl' : 'ltr';
};

/**
 * This function takes a float and decimal numbers as arguments, and returns a formated float
 * specified options
 * @param value
 * @param decimalPlaces
 */
export const formatFloat  = (value, decimalPlaces) => {
    return value.toFixed(decimalPlaces);
}

export const getStatusIcon = (status ) => {
    switch (status) {
        case 'COMPLETED':
            return <span className="badge badge-sm badge-success">{status}</span>;
        case 'FAILED':
            return <span className="badge badge-sm badge-failed">{status}</span>
        case 'REFUNDED':
            return <span className="badge badge-sm badge-refunded">{status}</span>
        case 'PENDING':
        case 'pending':
            return <span className="badge badge-sm badge-pending">{status}</span>
        case 'REFUNDED-ICON':
            return <CurrencyExchangeRoundedIcon className="status-icon-style" style={{ border: '1px solid rgb(52, 71, 103)', color: 'rgb(52, 71, 103)' }} />;
        case 'paypal':
            return ( <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                <img src={paypalicon} alt="paypal" style={{width:"2.5rem"}} />
            </div>)
        case 'credit-card':
            return (
                <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                    <img src={visa} alt="Visa" style={{ height: '2rem' }} />
                    <img src={master} alt="Mastercard" style={{ height: '2rem' }} />
                </div>
            );
        default:
            return null;
    }
};


/**
 * Returns the current date in the 'YYYY-MM-DD' format based on the local timezone.
 * @returns {string} Formatted date (YYYY-MM-DD)
 */
export const getFormattedTodayDate = () => {
    const today = new Date();
    return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
};

export function downloadFile(content, fileName, contentType) {

    const a = document.createElement('a');
    const file = new Blob([content], {type: contentType});
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
}

/**
 * Converts time in "HH:MM:SS" format to total minutes.
 * @param {string} duration - Time duration in the format "HH:MM:SS".
 * @returns {number} Total minutes.
 */
export const formatTimeToMinutes = (duration) => {

    if (typeof duration != "string") {
        return 0;
    }
    const [hours, minutes, seconds] = duration.split(':').map(Number);
    return (hours * 60) + minutes + (seconds / 60);
};

export const downloadFileFromUrl = (fileName, url) => {

    fetch(url).then((response) => {
        response.blob().then((blob) => {

            // Creating new object of PDF file
            const fileURL =
                window.URL.createObjectURL(blob);

            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = fileName;
            alink.click();
        });
    });
};

const createSection = (headerText, pageNumber, bodyText, addPageBreak = true) => {
    const children = [];

    if (headerText.trim()) {
        if (pageNumber === 1) {
            children.push(new TextRun({
                text: `\n**${headerText}:**\n`,
                color: "0000FF",
                bold: true,
                size: 20,
            }));
            children.push(new TextRun({
                text: `-- Page ${pageNumber} --`,
                color: "808080",
                bold: false,
                size: 18,
            }));
        } else {
            children.push(new TextRun({
                text: `${headerText} -- Page ${pageNumber} --`,
                color: "808080",
                bold: false,
                size: 18,
            }));
        }
    }

    if (bodyText.trim()) {
        children.push(new TextRun({
            text: `\n${bodyText}`,
            break: 1,
        }));
    }

    let paragraph = new Paragraph({
        children,
        spacing: {
            after: 200,
        },
    })
    return addPageBreak ? [paragraph, new Paragraph({ pageBreakBefore: true })] : [paragraph];
};

const createPageSections = (paragraphsByPages, pages) => {
    const ocrPageSections = [];
    const translationPageSections = [];

    pages.forEach((page) => {
        if (page.content.trim() && paragraphsByPages.ocr_text) {
            ocrPageSections.push(
                ...createSection(`Extracted Text`, page.num, page.content)
            );
        }

        if (page.translated_text.trim() && paragraphsByPages.translated_text) {
            translationPageSections.push(
                ...createSection(`Translated Text`, page.num, page.translated_text)
            );
        }
    });

    return [ocrPageSections, translationPageSections];
};

const createOtherSections = (paragraphs) => {
    return paragraphs
        .filter(paragraph => paragraph.trim() !== "")
        .map((paragraph) => {
            const [header, ...rest] = paragraph.split('\n');
            const bodyText = rest.join('\n\n').trim();

            return new Paragraph({
                children: [
                    new TextRun({
                        text:  "\n" + header,
                        color: "0000FF",
                        bold: true,
                    }),
                    new TextRun({
                        text: bodyText,
                        break: 1,
                    }),
                ],
                spacing: {
                    after: 200,
                },
            });
        });
};

export const downloadDocFile = async (fileName, pages, paragraphsByPages, fields) => {
    const [ocrPageSections, translationPageSections] = createPageSections(paragraphsByPages, pages);

    const otherSections = createOtherSections(fields);

    const doc = new Document({
        sections: [
            {
                properties: {},
                children: [...ocrPageSections, ...translationPageSections, ...otherSections]
            }
        ]
    });

    Packer.toBlob(doc).then((blob) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = fileName;
        a.click();
    });
};

// Function to format data to URL query parameters
export const formatDataToUrlQueryParams = (data) => {
    const params = new URLSearchParams();
    Object.entries(data).forEach(([key, value]) => {
        if (value) {
            params.append(key, value);
        }
    });
    return params.toString();
};

/**
 *
 * @param data
 * @param type
 * @param title
 */
export const prepareDownload = (data, type, title) => {
    const blob = new Blob(data, { type: type });
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    const extension = MIME_TYPE_TO_EXTENSION[type] || "unknown";
    link.setAttribute('download', `${title}.${extension}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
}

/**
 *
 * @param title
 * @returns {`${string}_${string}`}
 */
export const getTitleWithDate = (title) => {
    const today = new Date();
    return `${title}_${today.toJSON()}`;
};

/**
 *
 * @param title
 * @returns {*}
 */
export const getTitleWithoutDate = (title) => {
    return title.replace(/_[^_]*$/, '')
};

export const getPasswordPattern = () => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;
}

export const GradientSwitchStyle =  styled(Switch) (({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: '#fff',
            '& + .MuiSwitch-track': {
                background: 'linear-gradient(90deg, #98ec2d, #17ad37)',
                border: 'none',
            },
        },
        '& .MuiSwitch-switchBase': {
            '& + .MuiSwitch-track': {
                backgroundColor: '#d3d3d3',
                border: 'none',
            },
        },
        '& .MuiSwitch-thumb': {
            background: 'linear-gradient(90deg, #98ec2d, #17ad37)',
            border: 'none',
            boxShadow: 'none',
        }
    }));