import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { font } from "../../utlis/font/font";
import {getFormattedTodayDate} from "../../utlis/generalUtils";

export const downloadInvoicePDF = (order, profileData, logo) => {
    const doc = new jsPDF();

    // Top Bar
    doc.setFillColor(169, 169, 169);
    doc.rect(0, 0, 210, 5, 'F');

    // Title and Logo
    doc.setFontSize(18);
    doc.setTextColor(100);
    doc.addFileToVFS('Amiri-Regular.ttf', font);
    doc.addFont('Amiri-Regular.ttf', 'Amiri', 'normal');

    doc.setFont("Amiri", "bold");
    let title = '';
    let balanceDue = 0;
    if (order.status === "COMPLETED") {
        title = 'RECEIPT';
    } else {
        title = 'INVOICE';
        balanceDue = order.amount;
    }
    doc.text(title, 10, 30);
    let img = new Image();
    img.src = logo;
    doc.addImage(img, 'PNG', 160, 20, 30, 20);

    // Date and Receipt Number
    doc.setFontSize(8);
    doc.setTextColor(0);
    const lineCenterX = 175;
    doc.text('DATE', lineCenterX, 50, { align: 'center' });
    doc.text('RECEIPT NO.', lineCenterX, 64, { align: 'center' });
    doc.setDrawColor(150);
    doc.line(160, 52, 190, 52);
    doc.line(160, 66, 190, 66);

    // Use order data for date and receipt number
    if (order.status === "COMPLETED") {
        doc.setFont("Amiri", "normal");
        doc.text(order.payment_date, lineCenterX, 57, { align: 'center' });
    } else {
        doc.setFont("Amiri", "normal");
        doc.text(getFormattedTodayDate(order.date), lineCenterX, 57, { align: 'center' });
    }
    doc.setFont("Amiri", "normal");
    doc.text(order.order_id.toString(), lineCenterX, 69, { align: 'center' });

    // "FROM" Section
    doc.setFontSize(10);
    doc.setTextColor(150);
    doc.text('FROM', 10, 70);
    doc.line(10, 72, 60, 72);
    doc.setTextColor(0);
    doc.setFont("Amiri", "bold");
    doc.text('YAIGLOBAL', 10, 78);
    doc.setFont("Amiri", "normal");
    doc.text('Santa Clara, CA 95854, United States', 10, 83);
    doc.text('5201 Great America Parkway', 10, 88);

    // "TO" Section
    doc.setTextColor(150);
    doc.text('TO', 80, 70);
    doc.line(80, 72, 120, 72);
    doc.setTextColor(0);
    doc.text(order.payer_email || profileData?.email, 80, 78);
    doc.text(profileData?.address || '', 80, 83);
    doc.text(profileData?.phone || '', 80, 88);

    // Table with article data
    const articleData = order.articles.map(article => [
        article.title, `$${order.amount}`
    ]);

    doc.autoTable({
        head: [['DESCRIPTION', 'TOTAL']],
        body: articleData.length ? articleData : [['No items', '0.00']],
        startY: 120,
        headStyles: {
            fillColor: [0, 0, 0],
            textColor: [255, 255, 255],
            fontSize: 10,
            halign: 'center'
        },
        bodyStyles: {font: 'Amiri'},
        columnStyles: {
            0: { halign: 'left' },
            1: { halign: 'right' }
        },
        theme: 'grid',
        styles: { lineWidth: 0.2 }
    });

    // Footer with dynamic totals
    const footerY = doc.lastAutoTable.finalY + 10;
    const labels = ['SUBTOTAL'];
    const values = [`$${order.amount}`];

    doc.setFontSize(10);
    labels.forEach((label, index) => {
        const positionY = footerY + index * 10;
        doc.text(label, 140, positionY);
        doc.text(values[index], 180, positionY, { align: 'right' });
    });

    doc.setLineWidth(0.5);
    doc.line(140, footerY + 15, 195, footerY + 15);

    // Balance Due Section
    doc.setFontSize(12);
    doc.text('Balance Due', 140, footerY + 25, { align: 'left' });
    doc.text(`$${balanceDue}`, 180, footerY + 25, { align: 'right' });

    // Bottom Bar
    doc.setFillColor(169, 169, 169);
    const pageHeight = doc.internal.pageSize.height;
    doc.rect(0, pageHeight - 5, 210, 5, 'F');

    // Save PDF
    doc.save(`receipt_${order.order_id}.pdf`);
};

export const openTheEditor = (id) => {
    window.open(`/document?id=${id}`, "_blank");
}