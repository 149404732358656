import DigitizationContentComponent from "./editorDigitizationContent/DigitizationContentComponent";
import "../editorStyleSheet.css"
import BodyComponents from "./BodyComponents";
import PropTypes from "prop-types";
import {rerunService, updatePagesDataByArticleId} from "../../../axios-client";
import {useEffect, useState, useCallback, useRef} from "react";


const EditorBodyComponent = ({
    handleFullScreen,
    isFullScreen,
    mediaPath,
    containerWidth,
    scale,
    setPageNumber,
    setNumPages,
    forcePageNumber,
    setScale,
    numPages,
    pageNumber,
    setForcePageNumber,
    configWidth,
    updateConfigWidth,
    pages,
    sourceLanguage,
    targetLanguage,
    articleId,
    digitizationId,
    setCloseForcePageNumber,
    setNotification,
    setPages,
    bookmarkingSelected
}) => {
    const [updatedText, setUpdateText] = useState([])

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (updatedText.length > 0) {
                const message = "You have unsaved changes, are you sure you want to leave?";
                e.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [updatedText]);

    const addOrUpdateText = (num, newText, field) => {
        setUpdateText(prevTexts => {
            const existingText = prevTexts.find(item => item.num === num);

            if (existingText) {
                return prevTexts.map(item =>
                    item.num === num ? { ...item, [field]: newText } : item
                );
            } else {
                return [...prevTexts, { num, [field]: newText }];
            }
        });

        setPages(prevTexts => {
            const existingText = prevTexts.find(item => item.num === num);

            if (existingText) {
                return prevTexts.map(item =>
                    item.num === num ? { ...item, [field]: newText } : item
                );
            }
        });
    };

    const updateAudioPaths = (audioList) => {
        setPages(prevPages =>
            prevPages.map(page => {
                const matchingAudio = audioList.find(audio => audio.num === page.num);
                if (matchingAudio) {
                    return {...page, audio_path: matchingAudio.audio_path};
                }
                return page;
            })
        );
    };

    const regenerateBookmarking = () => {
        rerunService(digitizationId, {service_name: 'Bookmarking'})
            .then(() => {
                setNotification({ show: true, snackbarMessage: 'Regenerating Bookmarking in progress', severity: 'success' });
            })
            .catch(e => {
                setNotification({ show: true, snackbarMessage: 'An unexpected error occurred', severity: 'error' });
            })
    }
      
    const updatePages = () => {
        return updatePagesDataByArticleId(articleId, updatedText)
            .then((res) => {
                if (res.data && res.data.updated_pages) {
                    updateAudioPaths(res.data.updated_pages)
                }
                setUpdateText([])
                setNotification({ show: true, snackbarMessage: 'Pages updated successfully.', severity: 'success' });
            })
            .catch((error) => {
                setNotification({ show: true, snackbarMessage: 'An unexpected error occurred', severity: 'error' });
            });
    };
    

    return (
        <div className="digitizationEditorBody">
            <div className="body-component">
                <BodyComponents
                    mediaPath={mediaPath}   numPages={numPages}
                    setNumPages={setNumPages}  forcePageNumber={forcePageNumber}  scale={scale}
                    setScale={setScale}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    setForcePageNumber={setForcePageNumber}
                    containerWidth={containerWidth}
                    configWidth={configWidth}
                    setCloseForcePageNumber={setCloseForcePageNumber}
                    handleFullScreen={handleFullScreen}
                    isFullScreen={isFullScreen}
                    setNotification={setNotification}
                    articleId={articleId}
                    updatedText={updatedText}
                    setUpdateText={setUpdateText}
                />

                <DigitizationContentComponent
                    pageNumber={pageNumber}
                    configWidth={configWidth}
                    containerWidth={containerWidth}
                    updateConfigWidth={updateConfigWidth}
                    pages={pages}
                    digitizationId={digitizationId}
                    sourceLanguage={sourceLanguage}
                    targetLanguage={targetLanguage}
                    addOrUpdateText={addOrUpdateText}
                    updatedText={updatedText}
                    handleSave={updatePages}  
                    regenerateBookmarking={regenerateBookmarking}
                    bookmarkingSelected={bookmarkingSelected}
                />
            </div>
        </div>
    )

}


EditorBodyComponent.propTypes = {
    mediaPath: PropTypes.string,
    containerWidth: PropTypes.string,
    configWidth: PropTypes.string,
    numPages: PropTypes.number,
    pageNumber: PropTypes.number,
    scale: PropTypes.number,
    articleId: PropTypes.number,
    digitizationId: PropTypes.number,
    setPageNumber: PropTypes.func,
    forcePageNumber: PropTypes.number,
    setNumPages: PropTypes.func,
    setScale: PropTypes.func,
    setForcePageNumber: PropTypes.func,
    setCloseForcePageNumber: PropTypes.func,
    updateConfigWidth: PropTypes.func,
    sourceLanguage: PropTypes.string,
    targetLanguage: PropTypes.string,
    pages: PropTypes.array,
    setPages: PropTypes.func,
    handleFullScreen: PropTypes.func,
    setNotification: PropTypes.func,
    isFullScreen: PropTypes.bool,
    bookmarkingSelected: PropTypes.bool
};
export default EditorBodyComponent;