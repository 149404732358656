import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

function InsightsCard({
  bgColor = 'white',
  title,
  count,
  percentage = null,
  icon,
  direction = 'left',
  comparisonText,
}) {
  return (
    <Card>
      <SoftBox bgColor={bgColor} variant="gradient">
        <SoftBox p={2}>
          <Grid container alignItems="center">
            {direction === 'left' && (
              <Grid item xs={4}>
                <SoftBox
                  variant="gradient"
                  bgColor={bgColor === 'white' ? icon.color : 'white'}
                  color={bgColor === 'white' ? 'white' : 'dark'}
                  width="3rem"
                  height="3rem"
                  borderRadius="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  {icon.component}
                </SoftBox>
              </Grid>
            )}
            <Grid item xs={direction === 'left' ? 8 : 12}>
              <SoftBox ml={direction === 'left' ? 2 : 0} lineHeight={1}>
                {/* Title */}
                <SoftTypography
                  variant="caption"
                  color={bgColor === 'white' ? 'text' : 'white'}
                  opacity={bgColor === 'white' ? 1 : 0.7}
                  textTransform="capitalize"
                  fontWeight={title.fontWeight}
                >
                  {title.text}
                </SoftTypography>

                <SoftTypography
                  variant="h5"
                  fontWeight="bold"
                  color={bgColor === 'white' ? 'dark' : 'white'}
                >
                  {count}
                </SoftTypography>
                {(comparisonText || percentage) && (
                  <SoftBox display="flex" alignItems="center" mt={1}>
                    {comparisonText && (
                      <SoftTypography
                        variant="body2"
                        color="textSecondary"
                        fontWeight="bold"
                        sx={{ fontSize: '0.75rem', mr: 1 }} 
                      >
                        {comparisonText}
                      </SoftTypography>
                    )}

                    {percentage && (
                      <SoftBox display="flex" alignItems="center">
                        {percentage.icon}
                        <SoftTypography
                          variant="body2"
                          color={percentage.color}
                          fontWeight="bold"
                          sx={{ ml: 0.5 }}
                        >
                          {percentage.text}
                        </SoftTypography>
                      </SoftBox>
                    )}
                  </SoftBox>
                )}
              </SoftBox>
            </Grid>
            {direction === 'right' && (
              <Grid item xs={4}>
                <SoftBox
                  variant="gradient"
                  bgColor={bgColor === 'white' ? icon.color : 'white'}
                  color={bgColor === 'white' ? 'white' : 'dark'}
                  width="3rem"
                  height="3rem"
                  borderRadius="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  {icon.component}
                </SoftBox>
              </Grid>
            )}
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

InsightsCard.propTypes = {
  bgColor: PropTypes.oneOf([
    'white', 'primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark',
  ]),
  title: PropTypes.shape({
    fontWeight: PropTypes.oneOf(['light', 'regular', 'medium', 'bold']),
    text: PropTypes.string,
  }).isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      'primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark', 'white',
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    icon: PropTypes.node,
  }),
  icon: PropTypes.shape({
    color: PropTypes.string,
    component: PropTypes.node.isRequired,
  }).isRequired,
  direction: PropTypes.oneOf(['right', 'left']),
  comparisonText: PropTypes.string,
};

export default InsightsCard;
