import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "reusableComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "reusableComponents/Navbars/DashboardNavbar";
import Footer from "reusableComponents/Footer";
import CardComponent from "reusableComponents/Tables/Card";
import {digitize, getArchivesById, getUserDocuments} from "axios-client";
import { formatDate, formatDataToUrlQueryParams } from "utlis/generalUtils";
import InputAdornment from "@mui/material/InputAdornment";
import { useSoftUIController } from "../../context";
import SnackbarNotification from "../../reusableComponents/Snackbar/SnackbarNotification";

const MyPublications = () => {
    const [controller] = useSoftUIController();
    const { userId } = controller; 
    const [rows, setRows] = useState([]);
    const [articleCount, setArticleCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentFilter, setCurrentFilter] = useState("All Fields");
    const [searchText, setSearchText] = useState("");
    const [filterData, setFilterData] = useState({
      page: 1,
      size: 10,
      Title: "",
      Author: "",
      Date: "",
      Language: "",
      Subjects: "",
      all_fields: "",
    });
    const [notification, setNotification] = useState({
        show: false,
        snackbarMessage: "",
        severity: "",
    });

    useEffect(() => {
        const queryParams = formatDataToUrlQueryParams(filterData);
        fetchData(`?${queryParams}`);
    }, [filterData]);

    const refreshList = () => {
        const queryParams = formatDataToUrlQueryParams(filterData);
        fetchData(`?${queryParams}`);
    }
    useEffect(() => {
        refreshList()
    }, [filterData]);
  
    const fetchData = (params) => {
      setLoading(true);
      setError(null);

        getUserDocuments(params)
        .then((res) => {
          const formattedRows = res.data.data
            ? res.data.data.map((archive) => ({
                  id: archive.id,
                  picture_path: archive.article.picture_path,
                  title: archive.article.title,
                  subjects: archive.article.subjects,
                  author: archive.article.author,
                  summary: archive.summary,
                  source_language: archive.article.source_language,
                  target_language: archive.article.target_language,
                  publishedAt: archive.article.publication_date,
                  status: archive.status,
                  services_data: archive.services_data,
                  all_services_selected: archive.all_services_selected,
                  createdAt: archive.article.created_at,
                  bookmarking_path: archive.article.bookmarking_path,
                  articleId: archive.article.id,
                  digitizerId:archive.article.user_id,
                  has_audio_option:archive.article.has_audio_option,
              }))
            : [];
          setArticleCount(res.data.count);
          setRows(formattedRows);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching archives:", error);
          setError("Failed to load data. Please try again.");
          setLoading(false);
        });
    };
  

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  // Handle Enter key press in search input
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchSubmit();
    }
  };

  // Handle search submission
  const handleSearchSubmit = () => {
    // Reset all filters except the current one
    const updatedFilterData = {
      page: 1,
      size: 10,
      Title: "",
      Author: "",
      Date: "",
      Language: "",
      Subjects: "",
      all_fields: "",
      researcher_id: userId
    };

    // Map the current filter to the corresponding key in filterData
    const filterKeyMap = {
      "All Fields": "all_fields",
      Title: "title",
      Author: "author",
      Date: "date",
      Language: "language",
      Subjects: "subjects",
      researcher_id: "researcher_id"
    };

    const filterKey = filterKeyMap[currentFilter] || "all_fields";

    updatedFilterData[filterKey] = searchText.trim();

    setFilterData(updatedFilterData);
  };

  // Handle filter selection change
  const handleFilterChange = (event) => {
    setCurrentFilter(event.target.value);
  };

  // Pagination handlers
  const handlePageChange = (newPage) => {
    setFilterData((prev) => ({ ...prev, page: newPage }));
  };

  const handleRowsPerPageChange = (newSize) => {
    setFilterData((prev) => ({ ...prev, size: newSize, page: 1 }));
  };


  const getArchivesBySelectedDocument = async (id) => {
    try {
      const res = await getArchivesById(id);
      let data = res.data.data;

      return {
        details: {
          Digitization_date: data.article.created_at,
          Number_of_pages: data.article.page_count,
          Document_ID: data.id,
        },
        title: data.article.title,
        author: data.article.author,
        filename: data.article.filename,
        extension: data.article.extension,
        publishedAt: data.article.publication_date,
        source_language: data.article.source_language,
        target_language: data.article.target_language,
        picture_path: data.article.picture_path,
        ocr_text: data.ocr_text,
        translated_text: data.translated_text,
        summary: data.summary,
        bullet_points_summary: data.bullet_points_summary,
        description: data.description,
        bookmarking_path: data.bookmarking_path,
      };
    } catch (error) {
      console.error("Error fetching archives by id:", error);
    }
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={2} style={{ minHeight: "630px" }}>
        <SoftBox mb={2}>
          {/* Search and Filter Section */}
          <SoftBox
            mb={0.5}
            sx={{ marginRight: "8px", marginLeft: "8px", paddingTop: "30px" }}
          >
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={12} md={12}>
                <Box display="flex" alignItems="center">
                  {/* Search Input */}
                  <Box sx={{ flexGrow: 1 }}>
                    <TextField
                      variant="outlined"
                      placeholder="Search..."
                      value={searchText}
                      onChange={handleSearchChange}
                      onKeyDown={handleKeyDown}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{ marginLeft: "auto" }}
                          >
                            <IconButton
                              onClick={handleSearchSubmit}
                              color="primary"
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>

                  {/* 'Filter By' Dropdown */}
                  <Box sx={{ ml: 2 }}>
                    <Select
                      value={currentFilter}
                      onChange={handleFilterChange}
                      displayEmpty
                      variant="outlined"
                      inputProps={{ "aria-label": "Filter By" }}
                      style={{ minWidth: 150 }}
                    >
                      <MenuItem value="All Fields">All Fields</MenuItem>
                      <MenuItem value="Title">Title</MenuItem>
                      <MenuItem value="Author">Author</MenuItem>
                      <MenuItem value="Date">Date</MenuItem>
                      <MenuItem value="Language">Language</MenuItem>
                      <MenuItem value="Subjects">Subjects</MenuItem>
                    </Select>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </SoftBox>

          {/* Results Header */}
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={1}
            style={{ paddingTop: "16px", paddingBottom: "16px" }}
          >
            <SoftTypography variant="h6">
              {articleCount} results
            </SoftTypography>
          </SoftBox>

          {/* Error Message */}
          {error && (
            <SoftBox mb={2}>
              <SoftTypography color="error">{error}</SoftTypography>
            </SoftBox>
          )}

          {/* Loading Indicator or Data Display */}
          {loading ? (
            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="200px"
            >
              <SoftTypography>Loading...</SoftTypography>
            </SoftBox>
          ) : (
            <SoftBox>
              <Grid>
                <CardComponent
                  data={rows}
                  count={articleCount}
                  size={filterData.size}
                  page={filterData.page}
                  handlePageChange={handlePageChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  getArchivesBySelectedDocument={getArchivesBySelectedDocument}
                  loadingData={loading}
                  refreshList={refreshList}
                  setNotification={setNotification}
                />
              </Grid>
            </SoftBox>
          )}
        </SoftBox>
      </SoftBox>
      <SnackbarNotification
          notification={notification}
          setNotification={setNotification}
      />
      <Footer />
    </DashboardLayout>
  );
};

export default MyPublications;
