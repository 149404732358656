
// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard";

// Soft UI Dashboard React icons
import Shop from "reusableComponents/Icons/Shop";
import Office from "reusableComponents/Icons/Office";
import Departments from "./mainComponents/departments/Departments";
import Universities from "./mainComponents/universities/Universities";
import Librarians from "./mainComponents/librarians/Librarians";
import MyPublications from "layouts/mydocuments";
import { MdGroup } from 'react-icons/md';
import { HiMiniUsers } from "react-icons/hi2";
import ResearcherManagement from "./mainComponents/researcherManagement/ResearcherManagement";
import Document from "./reusableComponents/Icons/Document";
import Report from "./mainComponents/report/Report";
import { BiSolidReport } from "react-icons/bi";
import { FaUniversity } from "react-icons/fa";
import { FaUserTie } from "react-icons/fa6";
import PaymentList from "./mainComponents/payment/PaymentList";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import AgentDigitizations from "layouts/agent-publications";
import SwaggerDoc from "swagger/swaggerDocs";
import { BsFiletypeJson } from "react-icons/bs";
import { FaUsersCog } from 'react-icons/fa';

const sideBarItems = {
    'super-admin':
        [
            {
                type: "collapse",
                name: "Dashboard",
                key: "dashboard",
                route: "/dashboard",
                icon: <Shop size="12px"/>,
                component: <Dashboard/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Universities list",
                key: "universities-list",
                route: "/universities-list",
                icon: <FaUniversity size="12px"/>,
                component: <Universities/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Librarian Admins",
                key: "librarian-admins",
                route: "librarian-admins",
                icon:<MdGroup style={{fontSize: '14px'}} />,
                component: <Librarians/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Manage free agents",
                key: "free-agents",
                route: "free-agents",
                icon:<FaUsersCog size="14px" />,
                component: <Librarians/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Digital Databases",
                key: "documents",
                route: "/documents",
                icon: <Document size="12px" />,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Agent Digitizations",
                key: "agent-digitizations",
                route: "/agent-digitizations",
                icon: <FaUserTie size="12px"/>,
                component: <AgentDigitizations/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Report",
                key: "report",
                route: "/report",
                icon: <BiSolidReport size="12px"/>,
                component: <Report/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Payment list",
                key: "payment-list",
                route: "/payment-list",
                icon: <FaFileInvoiceDollar size="12px"/>,
                component: <PaymentList/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Swagger docs",
                key: "swagger-docs",
                route: "/api-docs",
                icon: <BsFiletypeJson size="14px"/>,
                component: <SwaggerDoc/>,
                noCollapse: true,
            }
        ],
    'librarian':
        [
            {
                type: "collapse",
                name: "Dashboard",
                key: "dashboard",
                route: "/dashboard",
                icon: <Shop size="12px"/>,
                component: <Dashboard/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Researcher Management",
                key: "researcher-management",
                route: "/researcher-management",
                icon: <HiMiniUsers size="12px"/>,
                component: <ResearcherManagement/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Digital Database",
                key: "documents",
                route: "/documents",
                icon: <Document size="12px" />,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "My Documents",
                key: "my-documents",
                route: "/my-documents",
                icon: <Document size="12px" />,
                component: <MyPublications/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Report",
                key: "report",
                route: "/report",
                icon: <BiSolidReport size="12px"/>,
                component: <Report/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Swagger docs",
                key: "swagger-docs",
                route: "/api-docs",
                icon: <BsFiletypeJson size="14px"/>,
                component: <SwaggerDoc/>,
                noCollapse: true,
            }
        ],
    'researcher':
        [
            {
                type: "collapse",
                name: "Dashboard",
                key: "dashboard",
                route: "/dashboard",
                icon: <Shop size="12px"/>,
                component: <Dashboard/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Digital Database",
                key: "documents",
                route: "/documents",
                icon: <Document size="12px" />,
                noCollapse: true,
            },
            // {
            //     type: "collapse",
            //     name: "My Documents",
            //     key: "my-documents",
            //     route: "/my-documents",
            //     icon: <Document size="12px" />,
            //     component: <MyPublications/>,
            //     noCollapse: true,
            // }
        ],
    'library-admin':
        [
            {
                type: "collapse",
                name: "Dashboard",
                key: "dashboard",
                route: "/dashboard",
                icon: <Shop size="12px"/>,
                component: <Dashboard/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Departments list",
                key: "departments-list",
                route: "/departments-list",
                icon: <Office size="12px"/>,
                component: <Departments/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Librarian Management",
                key: "librarian-management",
                route: "librarian-management",
                icon:<MdGroup style={{fontSize: '14px'}} />,
                component: <Librarians/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Digital Database",
                key: "documents",
                route: "/documents",
                icon: <Document size="12px"/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Report",
                key: "report",
                route: "/report",
                icon: <BiSolidReport size="12px"/>,
                component: <Report/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Swagger docs",
                key: "swagger-docs",
                route: "/api-docs",
                icon: <BsFiletypeJson size="14px"/>,
                component: <SwaggerDoc/>,
                noCollapse: true,
            }
        ],
    'free-agent':
        [
            {
                type: "collapse",
                name: "Dashboard",
                key: "dashboard",
                route: "/dashboard",
                icon: <Shop size="12px"/>,
                component: <Dashboard/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Digital Database",
                key: "documents",
                route: "/documents",
                icon: <Document size="12px" />,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Invoices",
                key: "invoices",
                route: "/invoices",
                icon: <FaFileInvoiceDollar size="12px"/>,
                component: <PaymentList/>,
                noCollapse: true,
            }
        ]
};

export default sideBarItems;
