import Tables from "../../reusableComponents/Tables/Table/TableCard";
import React, { useState, useEffect } from 'react';
import {
    getDepartementsList,
    getLibrarianAdminsList,
    getLibrariansList,
    getUniversities,
    deleteLibrarianAdmin,
    activateUsers, activateUserByRole
} from "../../axios-client";
import AddLibrarianDialog from "./AddLibrarianDialog";
import { IconButton } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import Tooltip from "@mui/material/Tooltip";
import EditLibrarianDialog from "./EditLibrarianDialog";
import {formatDataToUrlQueryParams, GradientSwitchStyle} from "../../utlis/generalUtils";
import ResetPasswordDialog from "./ResetPasswordDialog";
import AvatarComponent from "../../reusableComponents/Avatar/AvatarComponent";
import {useSoftUIController} from "../../context";
import {LIBRARIAN_ROLE, LIBRARY_ADMIN_ROLE, SUPER_ADMIN_ROLE} from "../../utlis/constant";
import { MdDelete } from "react-icons/md";
import DeleteLibrarianDialog from "./DeleteLibrarianDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SuiButton from "../../components/SoftButton";
import CircularProgress from '@mui/material/CircularProgress';
import { FaMinusCircle } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import userAvatar from "../../assets/images/userAvatar.png";
import Badge from "../../reusableComponents/Badge/Badge";
const DEFAULT_AVATAR_URL =userAvatar;


const Librarians = (props) => {
    const [controller, dispatch] = useSoftUIController();
    const {role} = controller;
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openAddLibrarianDialog, setOpenAddLibrarianDialog] = useState(false);
    const [openEditLibrarianDialog, setOpenEditLibrarianDialog] = useState(false);
    const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
    const [selectedLibrarian, setSelectedLibrarian] = useState(null);
    const [librariansCount, setLibrariansCount] = useState(0);
    const [librarianAdminsCount, setLibrarianAdminsCount] = useState(0);
    const [filterData, setFilterData] = useState({
        page: 1,
        size: 10,
    });
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [librarianToDelete, setLibrarianToDelete] = useState(null);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [isActivating, setIsActivating] = useState(false);
    const [updatedUserIds, setUpdatedUserIds] = useState([]);

    const handleOpenDeleteDialog = (librarian) => {
        setLibrarianToDelete(librarian);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setLibrarianToDelete(null);
    };

    useEffect(() => {
            fetchData(filterData);
    }, [filterData, role, isActivating]);


    const handlePageChange = (newPage) => {
        setFilterData((prev) => ({ ...prev, page: newPage }));
    };

    const handleRowsPerPageChange = (newSize) => {
        setFilterData((prev) => ({ ...prev, size: newSize, page: 1 }));
    };
    const handleUserStatus = (role, ids, isActive) => {

        if (!ids || ids.length === 0) {
            setSnackbar({
                open: true,
                message: `No inactive ${role} to activate!`,
                severity: 'info',
            });
            return;
        }

        setIsActivating(true)
        const userIds = Array.isArray(ids) ? ids.join(',') : ids;
        const payload = { is_active: isActive };

        setUpdatedUserIds(userIds)

        activateUserByRole(role, userIds, payload)
            .then(() => {
                setIsActivating(false)
                const userRole = role === LIBRARY_ADMIN_ROLE ? 'Library Admin' : 'User';
                const snackbarMessage = `${payload.is_active ? `${userRole} activated successfully!` : `${userRole} deactivated successfully!`}`;

                setSnackbar({
                    open: true,
                    message: snackbarMessage,
                    severity: "success"
                });
            })
            .catch(err => {
                setIsActivating(false)
                console.error(`Error activating ${role}:`, err);
                setSnackbar({
                    open: true,
                    message: `Failed to activate ${role} . Please try again!`,
                    severity: "error"
                })
            });
    }

    const fetchLibrarianAdmins = async (filterData) => {
        const queryParams = formatDataToUrlQueryParams(filterData);
        const res = await getLibrarianAdminsList(`?${queryParams}`);

        const formattedRows = res.data.users.map(librarianAdmin => {
            const badgeColor = librarianAdmin.is_active ? "success" : "secondary";
            const badgeContent = librarianAdmin.is_active ? "Active" : "Inactive";

            return {
                "Name": (
                    <AvatarComponent
                        name={librarianAdmin.name}
                        image={librarianAdmin.profile_picture ?? DEFAULT_AVATAR_URL}
                        bgColor={"secondary"}
                        variant={"rounded"}
                    />
                ),
                "Email": librarianAdmin.email,
                "Phone": librarianAdmin.phone,
                "University": librarianAdmin.university,
                "Status": (
                    <Badge
                        color={badgeColor}
                        badgeContent={badgeContent}
                        variant="gradient"
                    />
                ),
                "Action": (
                    <>
                        <Tooltip title="Edit librarian admin's information">
                            <IconButton
                                onClick={() => openEditDialog(librarianAdmin)}
                                size="small"
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Reset password">
                            <IconButton
                                onClick={() => handleOpenResetPasswordDialog(librarianAdmin)}
                                size="small"
                            >
                                <KeyIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete librarian admin">
                            <IconButton
                                onClick={() => handleOpenDeleteDialog(librarianAdmin)}
                                size="small"
                            >
                                <MdDelete />
                            </IconButton>
                        </Tooltip>
                    </>
                ),
                "Activate": (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <GradientSwitchStyle
                            checked={librarianAdmin.is_active}
                            onChange={() => handleUserStatus(LIBRARY_ADMIN_ROLE, [librarianAdmin.id], !librarianAdmin.is_active)}
                            icon={<FaMinusCircle />}
                            checkedIcon={<FaCheckCircle />}
                            disabled={isActivating && updatedUserIds.includes(librarianAdmin.id)}
                        />
                        {isActivating && updatedUserIds.includes(librarianAdmin.id) && (
                            <CircularProgress size={20} style={{ marginLeft: 10 }} />
                        )}
                    </div>
                )
            };
        });

        setLibrarianAdminsCount(res.data.count);
        setRows(formattedRows);
    };

    const fetchLibrarians = async (filterData) => {
        const queryParams = formatDataToUrlQueryParams(filterData);
        const res = await getLibrariansList(`?${queryParams}`);

        const formattedRows = res.data.users.map(librarian => ({
            "Name": (
                <AvatarComponent
                    name={librarian.name}
                    image={librarian.profile_picture ?? DEFAULT_AVATAR_URL}
                    bgColor={"secondary"}
                    variant={"rounded"}
                />
            ),
            "Email": librarian.email,
            "Phone": librarian.phone,
            "Department": librarian.departments.map(department => department.name).join(", "),
            "Action": (
                <>
                    <Tooltip title="Edit librarian's information">
                        <IconButton
                            onClick={() => openEditDialog(librarian)}
                            size="small"
                        >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Reset password">
                        <IconButton
                            onClick={() => handleOpenResetPasswordDialog(librarian)}
                            size="small"
                        >
                            <KeyIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ),
            "Activate": (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <GradientSwitchStyle
                        checked={librarian.is_active}
                        onChange={() =>
                            handleUserStatus(
                                LIBRARIAN_ROLE,
                                [librarian.id],
                                !librarian.is_active
                            )
                        }
                        icon={<FaMinusCircle />}
                        checkedIcon={<FaCheckCircle />}
                        disabled={isActivating && updatedUserIds.includes(librarian.id)}
                    />
                    {isActivating && updatedUserIds.includes(librarian.id) && (
                        <CircularProgress size={20} style={{ marginLeft: 10 }} />
                    )}
                </div>
            )
        }));

        setLibrariansCount(res.data.count);
        setRows(formattedRows);
    };

    const fetchData = async (filterData) => {
        if (!role) {
            return;
        }

        setLoading(true);
        if (role === SUPER_ADMIN_ROLE) {
            await fetchLibrarianAdmins(filterData);
        } else {
            await fetchLibrarians(filterData);
        }
        setLoading(false);
    };


    const handleOpenDialog = () => {
        setOpenAddLibrarianDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenAddLibrarianDialog(false);
    };
    const openEditDialog = (librarian) => {
        setSelectedLibrarian(librarian);
        setOpenEditLibrarianDialog(true);
    };

    const closeEditDialog = () => {
        setOpenEditLibrarianDialog(false);
        setSelectedLibrarian(null);
    };

    const handleOpenResetPasswordDialog = (librarian) => {
        setSelectedLibrarian(librarian);
        setOpenResetPasswordDialog(true);
    };

    const handleCloseResetPasswordDialog = () => {
        setOpenResetPasswordDialog(false);
        setSelectedLibrarian(null);
    };


    const refreshLibrariansList = () => {
        handleCloseDialog();
        fetchData(filterData);
    };
    const handleDeleteConfirm = (id) => {
        deleteLibrarianAdmin(id)
            .then(() => {
                setSnackbar({ open: true, message: 'Librarian admin user successfully deleted.', severity: 'success' });
                fetchData(filterData);
                handleCloseDeleteDialog();
            })
            .catch((error) => {
                setSnackbar({ open: true, message: error.response?.data?.message || 'Failed to delete librarian admin.', severity: 'error' });
                handleCloseDeleteDialog();
            });
    };
    const handleSnackbarClose = () => {
        setSnackbar({ open: false, message: "", severity: "" });
    };


    return (
        <div>
            <Tables
                rows={rows}
                title={role === SUPER_ADMIN_ROLE ? "Librarian Admins" : "Librarian"}
                showAddButton={true}
                ButtonName={role === SUPER_ADMIN_ROLE ? "Add Librarian Admin" : "Add Librarian"}
                loadingData={loading}
                size={filterData.size}
                page={filterData.page}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                count={role === SUPER_ADMIN_ROLE ? librarianAdminsCount : librariansCount}
                onButtonClick={handleOpenDialog}
            />
            {openAddLibrarianDialog && (
                <AddLibrarianDialog
                    open={openAddLibrarianDialog}
                    onClose={handleCloseDialog}
                    onLibrarianAdded={refreshLibrariansList}
                    setSnackbar={setSnackbar}
                    isSuperAdmin={role === SUPER_ADMIN_ROLE}
                />
            )}
            {openEditLibrarianDialog && (
                <EditLibrarianDialog
                    open={openEditLibrarianDialog}
                    onClose={closeEditDialog}
                    onLibrarianAdded={refreshLibrariansList}
                    librarianData={selectedLibrarian}
                    setSnackbar={setSnackbar}
                    isSuperAdmin={role === SUPER_ADMIN_ROLE}
                />
            )}
            {openResetPasswordDialog && (
                <ResetPasswordDialog
                    open={openResetPasswordDialog}
                    onClose={handleCloseResetPasswordDialog}
                    fetchData={refreshLibrariansList}
                    selectedUserData={selectedLibrarian}
                    setSnackbar={setSnackbar}
                    isSuperAdmin={role === SUPER_ADMIN_ROLE}
                />
            )}
            {openDeleteDialog && librarianToDelete && (
                <DeleteLibrarianDialog
                    open={openDeleteDialog}
                    onClose={handleCloseDeleteDialog}
                    onConfirm={handleDeleteConfirm}
                    librarian={librarianToDelete}
                />
            )}
            {/* Snackbar for feedback */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default Librarians;
