import {activateUsers, getResearchersList} from "../../axios-client";
import {useEffect, useState} from "react";
import Tables from "../../reusableComponents/Tables/Table/TableCard";
import Badge from "../../reusableComponents/Badge/Badge";
import AvatarComponent from "../../reusableComponents/Avatar/AvatarComponent";
import Action from "../actions/Action";
import SnackbarNotification from "../../reusableComponents/Snackbar/SnackbarNotification";
import {useSoftUIController} from "../../context";
import SuiButton from "../../components/SoftButton";
import CircularProgress from '@mui/material/CircularProgress';
import {formatDataToUrlQueryParams, GradientSwitchStyle} from "../../utlis/generalUtils";
import { FaMinusCircle } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import userAvatar from "../../assets/images/userAvatar.png";



const DEFAULT_AVATAR_URL =userAvatar;

const ResearcherManagement = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [notification, setNotification] = useState({show: false, snackbarMessage: '', severity: ""})
    const [showInactiveOnly, setShowInactiveOnly] = useState(false);
    const [inactiveUserIds, setInactiveUserIds] = useState([]);
    const [researchersCount,setResearchersCount] = useState(0);
    const [updatedUserIds, setUpdatedUserIds] = useState([]);
    const [isActivating, setIsActivating] = useState(false);




    const [filterData, setFilterData] = useState({
        page: 1,
        size: 10,
        is_active: null
    });

    useEffect(() => {

        if(filterData) {
            fetchData(filterData);
        }


    }, [filterData]);




    const handlePageChange = (newPage) => {
        setFilterData((prev) => ({ ...prev, page: newPage }));
    };

    const handleRowsPerPageChange = (newSize) => {
        setFilterData((prev) => ({ ...prev, size: newSize, page: 1 }));
    };


    const handleUserStatus = (ids, isActive) => {
        if (ids.length === 0) {
            setNotification({
                show: true,
                snackbarMessage: 'No inactive researchers to activate!',
                severity: 'info',
            });
            return;
        }
        setIsActivating(true)
        const userIds = Array.isArray(ids) ? ids.join(',') : ids;
        const payload = { is_active: isActive };
        setUpdatedUserIds(userIds)
        activateUsers(userIds, payload)
            .then(() => {
                setIsActivating(false)
                const snackbarMessage = payload.is_active ? 'Researcher activated successfully!' : 'Researcher deactivated successfully!';
                setNotification({
                    show: true,
                    snackbarMessage: snackbarMessage,
                    severity: "success"
                });

               fetchData(filterData)
            })
            .catch(err => {
                setIsActivating(false)
                console.error('Error activating user:', err);
                setNotification({
                    show: true,
                    snackbarMessage: 'Failed to activate researcher. Please try again!',
                    severity: "error"
                })
            });
    }

    const fetchData = (filterData) => {
        if(filterData) {
            const queryParams = formatDataToUrlQueryParams(filterData);
            getResearchersList(`?${queryParams}`)
                .then(res => {
                    let users = res.data.users;
                    setResearchersCount(res.data.count);
                    const inactiveIds = [];
                    const formattedRows = users.map(researcher => {

                        const badgeColor = researcher.is_active ? "success" : "secondary";
                        const badgeContent = researcher.is_active ? "Active" : "Inactive";
                        const variant = "gradient";

                        if (!researcher.is_active) {
                            inactiveIds.push(researcher.id);
                        }

                        return {
                            "Name": <AvatarComponent
                                name={researcher.name}
                                image={researcher.profile_picture || DEFAULT_AVATAR_URL}
                                email={researcher.email}
                                bgColor={"secondary"}
                                variant={"rounded"}
                            />,
                            "Phone": researcher.phone,
                            "Status": (
                                <Badge
                                    color={badgeColor}
                                    badgeContent={badgeContent}
                                    variant={variant}
                                />
                            ),
                            "Department": researcher.departments.map((dept) => dept.name).join(", "),
                            "Activate": (
                                <>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <GradientSwitchStyle
                                            checked={researcher.is_active}
                                            onChange={() => handleUserStatus([researcher.id], !researcher.is_active)}
                                            icon={<FaMinusCircle />}
                                            checkedIcon={<FaCheckCircle />}
                                            disabled={isActivating && updatedUserIds.includes(researcher.id)}
                                        />
                                        {isActivating && updatedUserIds.includes(researcher.id) && (
                                            <CircularProgress size={20} style={{ marginLeft: 10 }} />
                                        )}
                                    </div>
                                </>
                                        ),
                        };
                    });

                    setRows(formattedRows);
                    setInactiveUserIds(inactiveIds);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching researchers:', error);
                    setLoading(false);
                });
        }

    }


    useEffect(() => {
        fetchData();
    }, [isActivating]);

    const handleCheckChange = () => {
        const newShowInactiveOnly = !showInactiveOnly;
        setShowInactiveOnly(newShowInactiveOnly);

        setFilterData(prevParams => ({
            ...prevParams,
            is_active: !newShowInactiveOnly ? false : {},
        }));
    };


    return (

        <>
            <Tables rows={rows} title={"Researchers List"}
                    loadingData={loading} showAddButton={true} ButtonName={"Activate Researchers"} showIcon={false}
                    text={"Show inactive"} showCheckBox={true} optionChecked={showInactiveOnly}
                    onCheckChange={handleCheckChange}
                    onButtonClick={() => handleUserStatus(inactiveUserIds, true)}
                    size={filterData.size}
                    page={filterData.page}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    count={researchersCount}

            />

            <SnackbarNotification
                notification={notification}
                setNotification={setNotification}
            />

        </>

    );

}
export default ResearcherManagement;