import React, {useState, useEffect} from "react";
import SoftBox from "../../../components/SoftBox";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import voicesData from "./../../../constants/voices.json";
import VoiceOptionsSelection from "./VoiceOptionsSelection";

const Services = ({
                      selectedLanguage,
                      selectedTargetLanguage,
                      label,
                      services,
                      handleCheckboxServiceChange,
                      selectedServices,
                      setFormData,
                      errors,
                      setErrors
                  }) => {
    const [showVoiceSelection, setShowVoiceSelection] = useState(false);
    const [selectedVoice, setSelectedVoice] = useState({url: "", voice_name: ""});
    const [selectedAudioFormat, setSelectedAudioFormat] = useState("Select Format");
    const [currentAudio, setCurrentAudio] = useState(null);
    const [playVoice, setPlayVoice] = useState(null);
    const supportedLanguages = ['english', 'arabic', 'spanish', 'french', 'chinese', 'german', 'turkish', 'ottoman', 'japanese', 'russian']

    useEffect(() => {

        if (currentAudio) {
            currentAudio.addEventListener('ended', handleAudioEnd);
        }

        return () => {

            if (currentAudio) {
                currentAudio.pause();
                currentAudio.currentTime = 0;

                currentAudio.removeEventListener('ended', handleAudioEnd);
                currentAudio.pause();
                currentAudio.currentTime = 0;
            }
        };
    }, [currentAudio]);

    useEffect(() => {
        const audioBookService = services.find(service => service.name === 'Text to Speech');
        if (!selectedServices?.includes(audioBookService?.id)) {
            setShowVoiceSelection(null)
            setSelectedVoice({url: "", voice_name: ""})
            updateVoiceName('')
        } else {
            setShowVoiceSelection(true)
        }

        if (currentAudio) {
            currentAudio.pause();
            currentAudio.currentTime = 0;
            setPlayVoice(null)
        }

    }, [selectedServices]);

    useEffect(() => {
        setShowVoiceSelection(false)
        setSelectedVoice({url: "", voice_name: ""})
        updateVoiceName('')
        if (currentAudio) {
            currentAudio.pause();
            currentAudio.currentTime = 0;
            setPlayVoice(null)
        }

        const audioBookService = services.find(service => service.name === 'Text to Speech');

        if (audioBookService) {
            setFormData(prevState => ({
                ...prevState,
                services: selectedServices.filter(serviceId => serviceId !== audioBookService.id),
                audioFormat: '',
                voiceName: '',
            }));
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            audioFormat: '',
            voiceName: ''
        }));

        setSelectedAudioFormat('Select Format')

        const translationService = services.find(service => service.name === 'Translation');
        const translationServiceId = translationService ? translationService.id : null;

        if (selectedLanguage === "ottoman" && translationServiceId) {
            setFormData(prevFormData => {
                const servicesAlreadyIncluded = prevFormData.services.includes(translationServiceId);

                if (!servicesAlreadyIncluded) {
                    return {
                        ...prevFormData,
                        services: [...prevFormData.services, translationServiceId]
                    };
                }

                return prevFormData;
            });
        }

    }, [selectedLanguage]);

    const handleAudioEnd = () => {
        setPlayVoice(null);
    };

    const updateVoiceName = (voiceName) => {
        setFormData(prevState => ({
            ...prevState,
            voiceName: voiceName
        }));
        setErrors(prevErrors => ({...prevErrors, voiceName: ''}));
    }

    const updateAudioFormat = (audioFormat) => {
        setFormData(prevState => ({
            ...prevState,
            audioFormat: audioFormat
        }));
        setErrors(prevErrors => ({...prevErrors, audioFormat: ''}));
    }

    const handleCheckboxChange = (e, serviceId, serviceName) => {
        handleCheckboxServiceChange(e, serviceId);
        if(serviceName === "Text to Speech" && !e.target.checked){
            setErrors(prevErrors => ({
                ...prevErrors,
                audioFormat: '',
                voiceName: ''
            }));
        }
    };

    const handleAudioFormatChange = (event) => {
        setSelectedAudioFormat(event.target.value);
        updateAudioFormat(event.target.value)
    };

    const handlePlayVoice = (voiceUrl) => {
        if (currentAudio) {
            currentAudio.pause();
            currentAudio.currentTime = 0;
            setPlayVoice(null)
        }

        if (voiceUrl !== playVoice) {
            const newAudio = new Audio(voiceUrl);
            setCurrentAudio(newAudio);
            setPlayVoice(voiceUrl)

            newAudio.play().catch((error) => {
                if (error.name === 'AbortError') {
                    console.log('Play was interrupted by another pause or play action.');
                } else {
                    console.error('Error playing audio:', error);
                }
            });

        }

    };

    const voicesByLanguage = selectedLanguage === "ottoman" ? voicesData?.voices?.[selectedTargetLanguage] || {} : voicesData?.voices?.[selectedLanguage] || {};
    const femaleVoices = voicesByLanguage?.female || [];
    const maleVoices = voicesByLanguage?.male || [];

    return (
        <React.Fragment>
            <label htmlFor="subjectTerms" className="label-style">
                {label}
            </label>
            <SoftBox style={{display: "grid", gridTemplateColumns: "50% 50%"}}>
                {services.map((service, index) => {
                    if (service.name === 'Text to Speech' && (!supportedLanguages.includes(selectedLanguage) || (selectedLanguage === "ottoman" && !supportedLanguages.includes(selectedTargetLanguage)))) {
                        return null;
                    }

                    if (service.name === 'Diacritics' && selectedLanguage != "arabic") {
                        return null;
                    }

                    if (!service.mandatory) {
                        return (
                            <FormControlLabel
                                key={index}
                                style={{margin: "0px"}}
                                control={
                                    <Checkbox
                                        checked={selectedServices.includes(service.id)}
                                        onChange={(e) => handleCheckboxChange(e, service.id, service.name)}
                                        color="primary"
                                        disabled={service.name === 'Translation' && selectedLanguage === "ottoman"}
                                    />
                                }
                                label={service.name}
                            />
                        );
                    }
                    return null;
                })}
            </SoftBox>

            {showVoiceSelection && (
                <>
                    <div style={{display: "flex", marginTop: "20px", marginLeft: '10px'}}>
                        <VoiceOptionsSelection
                            voices={femaleVoices}
                            selectedVoice={selectedVoice}
                            setSelectedVoice={setSelectedVoice}
                            playVoice={playVoice}
                            handlePlayVoice={handlePlayVoice}
                            label="Select Female Voice"
                            updateVoiceName={updateVoiceName}
                        />
                        <VoiceOptionsSelection
                            voices={maleVoices}
                            selectedVoice={selectedVoice}
                            setSelectedVoice={setSelectedVoice}
                            playVoice={playVoice}
                            handlePlayVoice={handlePlayVoice}
                            label="Select Male Voice"
                            updateVoiceName={updateVoiceName}
                            style={{marginLeft: "130px"}}
                        />
                    </div>
                    {errors.voiceName && <p className="error-text">{errors.voiceName}</p>}

                    <label className="label-style">Select Audio Format</label>
                    <Select value={selectedAudioFormat} onChange={handleAudioFormatChange}>
                        <MenuItem value="Select Format" disabled>Select Format</MenuItem>
                        <MenuItem value="wav">WAV</MenuItem>
                        <MenuItem value="mp3">MP3</MenuItem>
                    </Select>
                    {errors.audioFormat && <p className="error-text">{errors.audioFormat}</p>}
                </>
            )}
        </React.Fragment>
    );
};

Services.propTypes = {
    label: PropTypes.string,
    selectedLanguage: PropTypes.string,
    selectedTargetLanguage: PropTypes.string,
    services: PropTypes.array,
    selectedServices: PropTypes.array,
    errors: PropTypes.object,
    setErrors: PropTypes.func,
    setFormData: PropTypes.func,
    handleCheckboxServiceChange: PropTypes.func,
};

export default Services;